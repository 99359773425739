/** @format */

import cric from "../Assets/Homepage/cric.svg";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import editorpick from "../Assets/Homepage/editorpick.svg";
import { useEffect, useState } from "react";
import {
  AuthToken,
  GetData,
  baseUrl,
  convertStringFormat,
  formatTitle,
} from "../Components/Integration/ApiIntegration";
import axios from "axios";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Helmet } from "react-helmet";
import Livescrore from "./Livescrore";
import {
  AdSideBanner,
  SpecialBox,
  TopBanner,
} from "../Components/HelpingComponent";
import CurrentSeries from "../Components/CurrentSeries";
import RankingSeries from "../Components/RankingSeries";

const Homepage = () => {
  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, right: "10px", zIndex: 1 }}
        onClick={onClick}
      >
        <span>&#8594;</span>
      </div>
    );
  };
  function dateUpdate(dateString) {
    const date = new Date(dateString);

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfWeek = daysOfWeek[date.getUTCDay()];
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];

    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    minutes = minutes < 10 ? "0" + minutes : minutes;

    const formattedDate = `${dayOfWeek}, ${day} ${month}, ${hours}:${minutes} ${ampm}`;

    return formattedDate;
  }
  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, left: "10px", zIndex: 1 }}
        onClick={onClick}
      >
        <span>&#8592;</span>
      </div>
    );
  };

  const editorsettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };
  const navigate = useNavigate();
  const [feacturePosts, setFeacturePosts] = useState([]);
  const [topStories, setTopStories] = useState([]);
  const [editorpicks, setEditorpicks] = useState([]);
  const [hompageBanner2, setHompageBanner2] = useState("");
  const [hompageBanner3, setHompageBanner3] = useState("");
  const [hompageBanner4, setHompageBanner4] = useState("");
  const [hompageBanner5, setHompageBanner5] = useState("");
  const [hompageBanner6, setHompageBanner6] = useState("");
  const [hompageBanner7, setHompageBanner7] = useState("");
  const [hompageBanner8, setHompageBanner8] = useState("");
  const [sliderData, setSliderData] = useState([]);

  const getSliderDataMatch = async () => {
    const response = await axios.get(
      baseUrl + "user/getMatchesList?type=mixed&paged=1&per_page=10&type=mixed",
      {
        params: {
          token: AuthToken,
        },
      }
    );

    setSliderData(response?.data?.response?.matches);
  };

  useEffect(() => {
    getSliderDataMatch();
  }, []);

  const getAllHomePageBanners = async () => {
    try {
      const res2 = await axios.get(`${baseUrl}admin/getAllPosts`);
      setHompageBanner2(
        res2?.data?.data?.find((item) => item.title === "hompageBanner2")?.image
      );
      setHompageBanner3(
        res2?.data?.data?.find((item) => item.title === "hompageBanner3")?.image
      );
      setHompageBanner4(
        res2?.data?.data?.find((item) => item.title === "hompageBanner4")?.image
      );
      setHompageBanner5(
        res2?.data?.data?.find((item) => item.title === "hompageBanner5")?.image
      );
      setHompageBanner6(
        res2?.data?.data?.find((item) => item.title === "hompageBanner6")
      );
      setHompageBanner7(
        res2?.data?.data?.find((item) => item.title === "hompageBanner7")
      );
      setHompageBanner8(
        res2?.data?.data?.find((item) => item.title === "hompageBanner8")
      );
    } catch (error) {
      console.error("Error fetching homepage banners:", error);
    }
  };

  useEffect(() => {
    getAllHomePageBanners();
  }, []);

  const getAllFeacturePosts = () => {
    GetData(`admin/getAllPosts`).then((res) => {
      const filteredData = res?.data?.filter(
        (item) => item.title === "FeacturePost"
      );
      setFeacturePosts(filteredData);
    });
  };

  const getAllTopPosts = () => {
    GetData("admin/getAllPosts").then((res) => {
      const filteredData = res?.data?.filter(
        (item) => item.title === "TopStories"
      );
      setTopStories(filteredData);
    });
  };

  const getAllEditorsPickData = () => {
    GetData("userAuth/getEditorPick").then((res) => {
      setEditorpicks(res?.data);
    });
  };

  useEffect(() => {
    getAllFeacturePosts();
    getAllTopPosts();
    getAllEditorsPickData();
  }, []);

  function timeAgo(createdAt) {
    const createdTime = new Date(createdAt).getTime();
    const currentTime = Date.now();
    const timeDifference = currentTime - createdTime;

    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);

    if (hoursDifference > 0) {
      return `${hoursDifference} hour${hoursDifference > 1 ? "s" : ""} ago`;
    } else if (minutesDifference > 0) {
      return `${minutesDifference} minute${
        minutesDifference > 1 ? "s" : ""
      } ago`;
    } else {
      return "just now";
    }
  }
  const CustomNextArrow1 = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#0F19AF",
          color: "white",
          marginRight: "2rem",
        }}
        onClick={onClick}
      />
    );
  };
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    variableWidth: true,
    nextArrow: <CustomNextArrow1 />,
    prevArrow: currentSlide === 0 ? null : <CustomPrevArrow />,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
  };

  return (
    <div className="max-container">
      <Helmet>
        <title>
          Cricinnings : Cricket Live Score, Cricket News, Predictions, Stats
        </title>
        <meta
          name="description"
          content="Live Cricket: Get live cricket scores, news, schedules, highlights, commentary, rankings, stats, & more updates for all international & domestic"
        />
      </Helmet>

      {sliderData && (
        <div className="top-slider-card-container">
          <Slider {...settings}>
            {sliderData?.map((item, index) => (
              <div key={index} className="top_slider_card">
                <div
                  onClick={() => {
                    if (item?.match_id) {
                      const teamAShortName = item.teama?.short_name
                        ?.toLowerCase()
                        .split(" ")
                        .join("-");
                      const teamBShortName = item.teamb?.short_name
                        ?.toLowerCase()
                        .split(" ")
                        .join("-");
                      const matchSuffix = convertStringFormat(
                        item?.subtitle
                      )?.toLowerCase();
                      const competitionTitle = item?.competition?.title
                        ?.toLowerCase()
                        .split(" ")
                        .join("-");
                      const competitionSeason =
                        item?.competition?.season?.toLowerCase();

                      const url = `/live-cricket-scores/${item.match_id}/${teamAShortName}-vs-${teamBShortName}-${matchSuffix}-${competitionTitle}-${competitionSeason}`;

                      navigate(url);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                  className="top_slider_card_div1"
                >
                  <div className="top_slider_card_div1_text">
                    <p
                      style={{
                        width: "90%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <span>{item?.subtitle} </span>
                      <span>
                        <Icon
                          icon="radix-icons:dot-filled"
                          width="1.2rem"
                          height="1.2rem"
                          style={{ color: "gray" }}
                        />
                      </span>
                      {item?.competition?.title}
                    </p>
                    <p>{item?.format_str?.slice(0, 5)}</p>
                  </div>
                  <div
                    style={{ lineHeight: "0" }}
                    className="top_slider_card_div2"
                  >
                    <div className="top_slider_card_div2_text">
                      <p>
                        <img
                          className="top_slider_card_div2_img"
                          src={item?.teama?.logo_url}
                          alt="logo"
                        />
                      </p>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span className="text-sm">
                          {item?.teama?.short_name}
                        </span>
                        <span>{item?.teama?.scores_full?.slice(0, 17)}</span>
                      </p>
                    </div>
                    <div className="top_slider_card_div2_text">
                      <p>
                        <img
                          className="top_slider_card_div2_img"
                          src={item?.teamb?.logo_url}
                          alt=""
                        />
                      </p>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span className="text-sm">
                          {item?.teamb?.short_name}
                        </span>
                        <span>{item?.teamb?.scores_full?.slice(0, 17)}</span>
                      </p>
                    </div>
                    <span>
                      {item?.status === 1 && (
                        <span
                          style={{
                            fontSize: "12px",
                            color: "rgb(163, 101, 1)",
                          }}
                        >
                          {dateUpdate(item?.date_start)}
                        </span>
                      )}
                      {item?.status === 2 && (
                        <span
                          style={{
                            fontSize: "12px",
                            color: "rgb(24, 102, 219)",
                          }}
                        >
                          {item?.result}
                        </span>
                      )}
                      {item?.status === 3 && (
                        <span
                          style={{
                            fontSize: "12px",
                            color: "rgb(24, 102, 219)",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item?.live?.slice(0, 30) + "..."}
                        </span>
                      )}
                    </span>
                  </div>
                </div>
                <div className="top_slider_card_div2_text11">
                  <div></div>
                  <div className="top_slider_card_div2_text11_text23">
                    {item?.competition?.total_teams > 2 && (
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            `/cricket-series/${
                              item?.competition?.cid
                            }/${formatTitle(item?.competition?.title)}-${
                              item?.competition?.season
                            }/points-table`
                          )
                        }
                      >
                        Points Table
                      </p>
                    )}
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          `/cricket-series/${
                            item?.competition?.cid
                          }/${formatTitle(item?.competition?.title)}-${
                            item?.competition?.season
                          }/matches`
                        )
                      }
                    >
                      Schedule
                    </p>
                  </div>
                </div>
              </div>
            ))}

            {hompageBanner2 && sliderData?.length >= 1 && (
              <div className="top_slider_card">
                <div className="top_slider_card_div1">
                  <img
                    src={hompageBanner2}
                    style={{ width: "100%", height: "100%" }}
                    alt="banner"
                  />
                </div>
              </div>
            )}
          </Slider>
        </div>
      )}

      <TopBanner img={hompageBanner3} className="mt-[2rem]" />

      <div className="bg-[#EEEEEE] pb-5  ">
        <Livescrore />
        {false && (
          <div className="flex justify-center pt-2 gap-5 main-div">
            <div>
              <div className="flex justify-between m-2">
                <div className="font-semibold small-text">FEATURE POSTS</div>
                <div
                  className="text-[#0F19AF] font-semibold cursor-pointer small-text"
                  onClick={() => navigate("/cricket-news")}
                >
                  SEE ALL
                </div>
              </div>

              <div className="feacturePosts">
                {feacturePosts?.map((item) => (
                  <div key={item?._id} className="feacturePosts_div">
                    <div
                      onClick={() =>
                        navigate(
                          `/single-blog/${item?._id}/${formatTitle(
                            item?.subtitle
                          )}`
                        )
                      }
                      className="w-[300px] flex gap-2 cursor-pointer full-width"
                    >
                      <div style={{ width: "130px" }}>
                        <img
                          style={{
                            height: "100px",
                            borderRadius: "10px",
                            maxWidth: "100%",
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                          src={item?.image || cric}
                          alt=""
                        />
                      </div>

                      <div className="text-sm">
                        <div className="bold-font small-text">
                          {item?.subtitle}
                        </div>
                        <span className="text-[#929394] xs-small-text">
                          {timeAgo(item?.createdAt)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <TopBanner img={hompageBanner4} className={"mt-2"} />
              <TopBanner img={hompageBanner5} className={"mt-2"} />

              {topStories?.length > 0 && (
                <div>
                  <div className="flex justify-between m-2">
                    <div className="font-semibold mt-2 medium-text">
                      TOP STORIES
                    </div>
                    <div
                      className="text-[#0F19AF] font-semibold cursor-pointer"
                      onClick={() => navigate("/feature-posts")}
                    ></div>
                  </div>
                  <div className="feacturePosts">
                    {topStories?.map((item) => (
                      <div
                        className="feacturePosts_div cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/single-blog/${item?._id}/${formatTitle(
                              item?.subtitle
                            )}`
                          )
                        }
                      >
                        <div className="flex gap-2 full-width">
                          <div>
                            <img src={cric} alt="" />
                          </div>

                          <div className="text-sm">
                            <div className="bold-font small-text">
                              {item?.subtitle}
                            </div>

                            <span className="text-[#929394] xs-small-text">
                              {timeAgo(item?.createdAt)} {item?.description}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {hompageBanner6 && (
                <div className="middleBannerBig">
                  <p>{hompageBanner6?.name}</p>
                  <div>
                    <p className="middleBannerImage">
                      <img src={hompageBanner6?.image} alt="middleBanner" />
                    </p>
                    <p className="middleBannerText">
                      <p>{hompageBanner6?.subtitle}</p>
                      <p>{hompageBanner6?.description}</p>
                    </p>
                  </div>
                </div>
              )}

              {editorpicks?.length > 0 && (
                <div className="small-padding">
                  <div className="text-sm mt-2 font-semibold medium-text ">
                    Editors Pick
                  </div>

                  <div className="w-[650px] h-[300px]  mt-2 pt-4 bg-white rounded-lg  shadow-lg full-width ">
                    <Slider {...editorsettings}>
                      {editorpicks?.map((item) => (
                        <div
                          className="editorPick cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/single-blog/${item?._id}/${formatTitle(
                                item?.subtitle
                              )}`
                            )
                          }
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "200px",
                              borderRadius: "10px 10px 0 0",
                              overflow: "hidden",
                            }}
                            src={item?.image || editorpick}
                            alt=""
                          />
                          <div
                            style={{
                              width: "90%",
                              margin: "auto",
                              lineHeight: "normal",
                              fontSize: "12px",
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "bold",
                                color: "black",
                                paddingTop: "0.2rem",
                                lineHeight: "normal",
                              }}
                            >
                              {item?.subtitle}
                            </p>
                            <p style={{ color: "gray", fontSize: "10px" }}>
                              {item?.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              )}
            </div>

            <div className="small-padding">
              <div className="w-[250px] mt-10 full-width zero-margin ">
                <CurrentSeries />
                <AdSideBanner img={hompageBanner7?.image} />
                <RankingSeries />
                <AdSideBanner img={hompageBanner8?.image} />
                <SpecialBox />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Homepage;
